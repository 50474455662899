import { Box, Card, CardActionArea, CardContent, Grid, styled } from '@mui/material';
import AppRegistrationIcon from '@mui/icons-material/AppRegistration';
import HowToRegIcon from '@mui/icons-material/HowToReg';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import MoreTimeIcon from '@mui/icons-material/MoreTime';
import EventRepeatIcon from '@mui/icons-material/EventRepeat';
import MilitaryTechIcon from '@mui/icons-material/MilitaryTech';
import CreditScoreIcon from '@mui/icons-material/CreditScore';
import StorefrontIcon from '@mui/icons-material/Storefront';
import Typography from "@mui/material/Typography";
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import ConstructionIcon from '@mui/icons-material/Construction';
import EditCalendarIcon from '@mui/icons-material/EditCalendar';
import RocketLaunchIcon from '@mui/icons-material/RocketLaunch';
import PsychologyIcon from '@mui/icons-material/Psychology';
import TravelExploreIcon from '@mui/icons-material/TravelExplore';
import VolumeUpIcon from '@mui/icons-material/VolumeUp';
import ReceiptIcon from '@mui/icons-material/Receipt';
import NotificationAddIcon from '@mui/icons-material/NotificationAdd';
import AssuredWorkloadIcon from '@mui/icons-material/AssuredWorkload';
import QuizIcon from '@mui/icons-material/Quiz';
import BeachAccessIcon from '@mui/icons-material/BeachAccess';
import MarkUnreadChatAltIcon from '@mui/icons-material/MarkUnreadChatAlt';
import { useTranslation } from "react-i18next";
import { useTheme } from '@mui/system';
import i18n from '../../../i18n';
import { motion } from "framer-motion"
import { ListPayment } from '../PaymentMethods/ListPayment/ListPayment';
import payLater from "../../../public/main/payLater.png";
import aiAssistant from "../../../public/main/Rastcom-AI.png";


export const WhyWe = () => {
    const { t } = useTranslation();
    const theme = useTheme();

    const hire = [
        {
            id: Math.random(),
            title: (i18n.language === "en" ? "Collective Advertising" : "Publicité collective"),
            icon: VolumeUpIcon
        },
        {
            id: Math.random(),
            title: (i18n.language === "en" ? "Appointment Scheduler" : "Planificateur de rendez-vous"),
            icon: EditCalendarIcon
        },
        {
            id: Math.random(),
            title: (i18n.language === "en" ? "Job Scheduler" : "Planificateur des demandes"),
            icon: AppRegistrationIcon
        },
        {
            id: Math.random(),
            title: (i18n.language === "en" ? "Job tracker" : "Suiveur de mission"),
            icon: HowToRegIcon
        },
        {
            id: Math.random(),
            title: (i18n.language === "en" ? "Invoicing" : "Facturation"),
            icon: ReceiptIcon
        },
        {
            id: Math.random(),
            title: (i18n.language === "en" ? "Payment System" : "Système de paiement"),
            icon: AssuredWorkloadIcon
        },
        {
            id: Math.random(),
            title: (i18n.language === "en" ? "Notifications" : "Notifications"),
            icon: NotificationAddIcon
        },
        {
            id: Math.random(),
            title: (i18n.language === "en" ? "Integrated Chat" : "Chat intégré"),
            icon: MarkUnreadChatAltIcon
        },
        {
            id: Math.random(),
            title: (i18n.language === "en" ? "Generative Ai" : "Ai générative"),
            icon: PsychologyIcon
        }
    ]

    const become = [
        {
            id: Math.random(),
            title: "Register",
            // description: "Create profile",
            icon: AppRegistrationIcon
        },
        {
            id: Math.random(),
            title: "Setup payment method",
            // description: "you can receive pay for your services with both fiat and crypto",
            icon: MonetizationOnIcon
        },
        {
            id: Math.random(),
            title: "Set your hourly rates",
            // description: "",
            icon: MoreTimeIcon
        },
        {
            id: Math.random(),
            title: "Set your calendar availability ",
            // description: "",
            icon: EventRepeatIcon
        },
        {
            id: Math.random(),
            title: "Provide best service possible to the client ",
            // description: "then approve your profile",
            icon: MilitaryTechIcon
        },
        {
            id: Math.random(),
            title: "Get paid",
            // description: "For Pro",
            icon: CreditScoreIcon
        }
    ]

    // Styled component for creating a pentagon shape
    const PentagonCardActionArea = styled(CardActionArea)({
        // clipPath: 'polygon(50% 0%, 100% 38%, 82% 100%, 18% 100%, 0% 38%)',
        clipPath: 'polygon(40% 0%, 60% 0%, 100% 50%, 60% 100%, 40% 100%, 0% 50%)',
        backgroundColor: "#F9FAFB",
        borderRadius: "10px",
    });

    return (
        <Box
            sx={{
                backgroundColor: "#f2f5f7",
                paddingBottom: '20px',
                // backgroundImage: `url(${Luna})`, // Use the imported variable
                backgroundSize: 'cover',
                marginTop: '0px'
            }}
        >
            {/* <ListPayment
                // t={t}
                header={t('aiAssistant.header')}
                description={t('aiAssistant.description')}
                img={aiAssistant}
            /> */}
            <Typography fontSize={28} fontWeight="600" align="center" variant="h3" sx={{ margin: "0px 0px", padding: '10px', color: '#16A34A' }}>
                {/* {t('WhyWe.title')} */}
                <Typography
                    align="center"
                    sx={{
                        margin: "20px 40px",
                        color: "#4B5563",
                    }}
                >
                    <Grid container gap={{ xs: 4, sm: 4, md: .5 }}
                        sx={{
                            justifyContent: 'center',
                            // padding:'20px',
                            // boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.9)',
                        }}
                    >
                        {/* Card 1 */}
                        <Grid item xs={12} sm={12} md={6}
                            sx={{
                                ":hover": {
                                    cursor: 'pointer',
                                    boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.9)',
                                },
                            }}
                        >
                            <motion.div
                                initial={{
                                    scale: .9
                                }}
                                whileInView={{
                                    scale: 1
                                }}
                                transition={{
                                    duration: 1
                                }}
                                viewport={{ once: true }}
                            >
                                <Typography
                                    variant="h3"
                                    fontSize={18}
                                    fontWeight="600"
                                    align="center"
                                    sx={{
                                        color: '#32325d',
                                        [theme.breakpoints.down('lg')]: {
                                            height: i18n.language === 'en' ? '70px' : '85px',
                                            padding: '10px',
                                        },
                                        background: 'linear-gradient(to right, rgba(224, 254, 224, 1), rgba(224, 254, 224, 0), rgba(224, 224, 224, 0), rgba(224, 224, 224, 1))',
                                        borderRadius: "10px 10px 0 0",
                                        padding: '20px',
                                        transition: 'box-shadow 0.3s ease-in-out', // Note the quotes around the property value
                                        boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.9)', // Add the desired shadow on hover
                                    }}
                                >

                                    <RocketLaunchIcon sx={{ color: '#FF7600' }} /> {t('WhyWe.title')}
                                </Typography>
                                <Typography
                                    sx={{
                                        backgroundColor: "#F9FAFB",
                                        borderRadius: "0 0 10px 10px",
                                        padding: '20px',
                                        transition: 'box-shadow 0.3s ease-in-out', // Note the quotes around the property value
                                        boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)', // Add the desired shadow on hover
                                        // height: '250px', // Set a fixed height for consistency
                                        [theme.breakpoints.down('lg')]: {
                                            height: i18n.language === 'en' ? '280px' : '330px'
                                        },
                                        [theme.breakpoints.down('sm')]: {
                                            // height: i18n.language === 'en' ? '300px' : '370px'
                                            height: 'auto'
                                        },
                                        [theme.breakpoints.up('lg')]: {
                                            height: i18n.language === 'en' ? '200px' : '210px'
                                        },
                                    }}
                                >
                                    {t('WhyWe.WithRastcom')}
                                </Typography>
                            </motion.div>
                        </Grid>

                        {/* Card 2 */}
                        <Grid item xs={12} sm={12} md={4}
                            sx={{
                                ":hover": {
                                    cursor: 'pointer',
                                    boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.9)',
                                },
                            }}
                        >
                            <motion.div
                                initial={{
                                    scale: .9
                                }}
                                whileInView={{
                                    scale: 1
                                }}
                                transition={{
                                    duration: 1
                                }}
                                viewport={{ once: true }}
                            >
                                <Typography
                                    variant="h3"
                                    fontSize={18}
                                    fontWeight="600"
                                    align="center"
                                    sx={{
                                        color: '#32325d',
                                        [theme.breakpoints.down('lg')]: {
                                            height: i18n.language === 'en' ? '70px' : '85px',
                                            padding: '10px',
                                        },
                                        background: 'linear-gradient(to right, rgba(224, 254, 224, 1), rgba(224, 254, 224, 0), rgba(224, 224, 224, 0), rgba(224, 224, 224, 1))',
                                        borderRadius: "10px 10px 0 0",
                                        padding: '20px',
                                        transition: 'box-shadow 0.3s ease-in-out', // Note the quotes around the property value
                                        boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.9)', // Add the desired shadow on hover
                                    }}
                                >
                                    <BeachAccessIcon sx={{ color: '#FF7600' }} /> {t('WhyWe.cancelationProtectionTitle')}
                                </Typography>
                                <Typography
                                    sx={{
                                        backgroundColor: "#F9FAFB",
                                        borderRadius: "0 0 10px 10px",
                                        padding: '20px',
                                        transition: 'box-shadow 0.3s ease-in-out', // Note the quotes around the property value
                                        boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)', // Add the desired shadow on hover
                                        [theme.breakpoints.down('lg')]: {
                                            // height: i18n.language === 'en' ? '300px' : '370px'
                                            height: 'auto'
                                        },
                                        [theme.breakpoints.up('lg')]: {
                                            // height: '280px', // Default height for medium and larger devices
                                            height: i18n.language === 'en' ? '200px' : '210px'
                                        },
                                    }}
                                >
                                    {t('WhyWe.cancelationProtectionBody')}
                                </Typography>
                            </motion.div>
                        </Grid>

                        {/* Card 3 */}
                        <Grid item xs={12} sm={12} md={4}
                            sx={{
                                ":hover": {
                                    cursor: 'pointer',
                                    boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.9)',
                                },
                            }}
                        >
                            <motion.div
                                initial={{
                                    scale: .9
                                }}
                                whileInView={{
                                    scale: 1
                                }}
                                transition={{
                                    duration: 1
                                }}
                                viewport={{ once: true }}
                            >
                                <Typography
                                    variant="h3"
                                    fontSize={18}
                                    fontWeight="600"
                                    align="center"
                                    sx={{
                                        color: '#32325d',
                                        [theme.breakpoints.down('lg')]: {
                                            height: i18n.language === 'en' ? '70px' : '85px',
                                            padding: '10px',
                                        },
                                        background: 'linear-gradient(to right, rgba(224, 254, 224, 1), rgba(224, 254, 224, 0), rgba(224, 224, 224, 0), rgba(224, 224, 224, 1))',
                                        borderRadius: "10px 10px 0 0",
                                        padding: '20px',
                                        transition: 'box-shadow 0.3s ease-in-out', // Note the quotes around the property value
                                        boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.9)', // Add the desired shadow on hover
                                    }}
                                >
                                    <TravelExploreIcon sx={{ color: '#FF7600' }} /> {t('WhyWe.globalReachTitle')}
                                </Typography>
                                <Typography
                                    sx={{
                                        backgroundColor: "#F9FAFB",
                                        borderRadius: "0 0 10px 10px",
                                        padding: '20px',
                                        transition: 'box-shadow 0.3s ease-in-out', // Note the quotes around the property value
                                        boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)', // Add the desired shadow on hover
                                        [theme.breakpoints.down('lg')]: {
                                            height: i18n.language === 'en' ? '210px' : '240px'
                                            // height:'auto'
                                        },
                                        [theme.breakpoints.up('md')]: {
                                            height: '200px', // Default height for medium and larger devices
                                        },
                                        [theme.breakpoints.up('lg')]: {
                                            // height: '280px', // Default height for medium and larger devices
                                            height: i18n.language === 'en' ? '160px' : '170px'
                                        },
                                    }}
                                >
                                    {t('WhyWe.globalReachBody')}
                                </Typography>
                            </motion.div>
                        </Grid>
                        <Grid item xs={12} sm={12} md={6}
                            sx={{
                                ":hover": {
                                    cursor: 'pointer',
                                    boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.9)',
                                },
                            }}
                        >
                            <motion.div
                                initial={{
                                    scale: .9
                                }}
                                whileInView={{
                                    scale: 1
                                }}
                                transition={{
                                    duration: 1
                                }}
                                viewport={{ once: true }}
                            >
                                <Typography
                                    variant="h3"
                                    fontSize={18}
                                    fontWeight="600"
                                    align="center"
                                    sx={{
                                        color: '#32325d',
                                        [theme.breakpoints.down('lg')]: {
                                            height: i18n.language === 'en' ? '70px' : '85px',
                                            padding: '10px',
                                        },
                                        background: 'linear-gradient(to right, rgba(224, 254, 224, 1), rgba(224, 254, 224, 0), rgba(224, 224, 224, 0), rgba(224, 224, 224, 1))',
                                        borderRadius: "10px 10px 0 0",
                                        padding: '20px',
                                        transition: 'box-shadow 0.3s ease-in-out', // Note the quotes around the property value
                                        boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.9)', // Add the desired shadow on hover
                                    }}
                                >

                                    <StorefrontIcon sx={{ color: '#FF7600' }} />{t('WhyWe.rastcomBusinessTitle')}
                                </Typography>
                                <Typography
                                    sx={{
                                        backgroundColor: "#F9FAFB",
                                        borderRadius: "0 0 10px 10px",
                                        padding: '20px',
                                        transition: 'box-shadow 0.3s ease-in-out', // Note the quotes around the property value
                                        boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)', // Add the desired shadow on hover
                                        // height: '250px', // Set a fixed height for consistency
                                        [theme.breakpoints.down('lg')]: {
                                            height: i18n.language === 'en' ? 'auto' : '240px'
                                        },
                                        [theme.breakpoints.down('md')]: {
                                            // height: i18n.language === 'en' ? '300px' : '370px'
                                            height: 'auto'
                                        },
                                        [theme.breakpoints.up('lg')]: {
                                            height: i18n.language === 'en' ? '160px' : '170px'
                                        },
                                    }}
                                >
                                    {t('WhyWe.rastcomBusinessBody')}
                                </Typography>
                            </motion.div>
                        </Grid>
                    </Grid>
                </Typography>
                <ListPayment
                    // t={t}
                    header={t('hireNowPayLater.header')}
                    description={t('hireNowPayLater.description')}
                    img={payLater}
                />
                {/* </Grid> */}
                <Typography fontSize={24} fontWeight="600" align="center" variant="h3"
                    sx={{
                        marginTop: "50px",
                        borderRadius: '30px',
                        padding: '20px',
                        color: '#32325d',
                        backgroundColor: "#F9FAFB",
                        // background: 'linear-gradient(to right, rgba(224, 254, 224, 1), rgba(224, 254, 224, 0), rgba(224, 224, 224, 0), rgba(224, 224, 224, 1))',
                        display: 'inline-block', // Set display to inline-block
                        width: 'auto', // Set width to auto
                    }}
                >
                    <ConstructionIcon sx={{ color: '#FF7600' }} /> {t('WhyWe.seemlessTolls')}
                </Typography>
            </Typography>
            <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
                // ml={{md:1.5}}
                spacing={2}
                wrap="wrap"
                sx={{
                    padding: "0 5vw"
                }}
            >
                {hire.map((step, index) => (
                    <Grid item xs={4} sm={6} md={1} key={step.id}>
                        <motion.div
                            initial={{
                                scale: 0
                            }}
                            whileInView={{
                                scale: 1
                            }}
                            transition={{
                                duration: 1
                            }}
                            viewport={{ once: true }}
                        >
                            <Card sx={{ borderRadius: "50%", width: 100, height: 100, display: 'flex', alignItems: 'center', justifyContent: 'center', boxShadow: '0px 0px 20px 8px rgba(22, 163, 74, 0.2)' }}>
                                <CardContent sx={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center" }}>
                                    <step.icon sx={{ color: '#FF7600', fontSize: 25 }} />
                                    <Box sx={{ mt: 2 }}>
                                        <Typography sx={{ fontSize: 12 }} align="center">{step.title}</Typography>
                                    </Box>
                                </CardContent>
                            </Card>
                        </motion.div>
                    </Grid>
                ))}
            </Grid>

            <Grid item xs={12} sm={12} md={4} m={2}>
                <Typography fontSize={28} fontWeight="600" align="center" variant="h3"
                    sx={{
                        color: '#32325d',
                        margin: '0',
                        background: 'linear-gradient(to right, rgba(224, 224, 224, 1), rgba(224, 224, 224, 0), rgba(255, 255, 255, 0), rgba(255, 255, 255, 1))',
                        borderRadius: "10px 10px 0 0",
                        padding: '20px',
                        transition: 'box-shadow 0.3s ease-in-out', // Note the quotes around the property value
                        boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)', // Add the desired shadow on hover
                    }}
                >
                    <EmojiEventsIcon sx={{ color: '#FF7600' }} /> {t('WhyWe.cuttingEdgeTitle')}
                    <Typography fontSize={18} fontWeight="600" align="center" variant="h3" sx={{ paddingTop: '10px', color: '#32325d' }}>
                        <QuizIcon sx={{ color: '#FF7600' }} /> {t('WhyWe.noMore')}
                    </Typography>
                </Typography>
                <Typography
                    align="center"
                    sx={{
                        backgroundColor: "#F9FAFB",
                        borderRadius: "0 0 10px 10px",
                        // margin: "10px 0 0 10px",
                        padding: '20px',
                        transition: 'box-shadow 0.3s ease-in-out', // Note the quotes around the property value
                        boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)', // Add the desired shadow on hover
                    }}
                >
                    {t('WhyWe.cuttingEdgeBody')}
                </Typography>
            </Grid>
        </Box>
    );
};
