import { ListPayment } from "../PaymentMethods/ListPayment/ListPayment";
import aiAssistant from "../../../public/main/Rastcom-AI.png";
import { useTranslation } from "react-i18next";
import { Grid, Typography } from "@mui/material";


export default function Ai() {
    const { t } = useTranslation();

    return (
        <>
            <Typography fontSize={26} fontWeight="600" align="center" variant="h3"
                sx={{
                    color: '#32325d',
                    marginBottom: '20px',
                    // background: 'linear-gradient(to right, rgba(224, 224, 224, 1), rgba(224, 224, 224, 0), rgba(255, 255, 255, 0), rgba(255, 255, 255, 1))',
                    background: 'linear-gradient(to right, rgba(224, 254, 224, 1), rgba(224, 254, 224, 0), rgba(224, 224, 224, 0), rgba(224, 224, 224, 1))',
                    // borderRadius: "10px",
                    padding: '20px',
                    transition: 'box-shadow 0.3s ease-in-out', // Note the quotes around the property value
                    // boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)', // Add the desired shadow on hover
                }}
            >
                {t('aiAssistant.Title')}
            </Typography>
            <ListPayment
                header={t('aiAssistant.header')}
                description={t('aiAssistant.description')}
                img={aiAssistant}
            />
        </>
    )
}