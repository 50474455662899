import { Divider, Grid } from "@mui/material";
import Typography from "@mui/material/Typography";
import { useTranslation } from 'react-i18next';
import { motion } from "framer-motion"
import image1 from "../../../../public/main/Rastcom - forBusiness.png"
import image2 from "../../../../public/main/forBusiness2.png"

function ForBusiness() {
    const { t, i18n } = useTranslation();

    return (
        <Grid container

            sx={{
                display: 'flex',
                justifyContent: 'center',
                backgroundColor: "#F9FAFB",
                alignItems: "center",
                flexDirection: { xs: 'column', md: "row" },
                marginBottom: 0,
            }}
        >
            <Grid item md={5} sm={11} xs={11} m={2}>
                <Divider sx={{ color: '#FF7600' }} role="presentation">
                    <Typography
                        sx={{
                            fontSize: 26,
                            fontWeight: 600,
                            padding: '20px',
                            color: '#32325d'
                        }}
                    >
                        {t('forBusiness.title')}
                    </Typography>
                </Divider>
                <Typography
                    sx={{
                        fontSize: 15,
                        color: "#6B7280",
                        padding: '20px',
                    }}
                >
                    {t('forBusiness.description')}
                </Typography>
            </Grid>
            <Grid item xs={11} md={5} lg={5}
                sx={{
                    width: '80%',
                    height: 'auto',
                    alignItems: 'center',
                    padding: '10px 0 20px 0',
                    borderRadius: '10px',
                    margin: 'auto'
                }}
            >
                <motion.div
                    initial={{
                        scale: .7
                    }}
                    whileInView={{
                        scale: .9
                    }}
                    transition={{
                        duration: .5
                    }}
                    viewport={{ once: true }}
                >
                    <img
                        src={image1}
                        style={{
                            // border: '1px solid green',
                            // borderRadius: "15px",
                            marginTop: "20px",
                            maxWidth: "100%",
                            minHeight: "auto"
                        }}
                    />
                </motion.div>
                {/* </motion.div> */}
            </Grid>
            <Grid item md={12} sm={11} xs={11} m={2}>
                <Divider sx={{ color: '#FF7600' }} role="presentation">
                    <Typography
                        sx={{
                            fontSize: 26,
                            fontWeight: 600,
                            padding: '20px',
                            color: '#32325d'
                        }}
                    >
                        {t('forBusiness.title2')}
                    </Typography>
                </Divider>
                <Typography
                    sx={{
                        fontSize: 15,
                        color: "#6B7280",
                        padding: '20px',
                    }}
                >
                    {t('forBusiness.description2')}
                </Typography>
            </Grid>
            <img
                src={image2}
                style={{
                    // border: '1px solid green',
                    // borderRadius: "15px",
                    width: '50vw',
                    height: 'auto'
                }}
            />
        </Grid>
    )
}

export default ForBusiness
